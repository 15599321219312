import { useIsJobEditable, useJobActions } from "@streamtimefe/state";
import { webapi } from "@streamtimefe/state/api";
import { useMutation } from "@tanstack/react-query";
import type { PropsWithChildren } from "react";
import { createContext, useCallback, useContext } from "react";

import type { TSaveOptions } from "./types";

type TJobSaveContext = (options?: TSaveOptions) => void;

export const JobSaveContext = createContext<TJobSaveContext>(
  undefined as unknown as TJobSaveContext
);

export function useJobSave() {
  const context = useContext(JobSaveContext);
  if (!context) {
    throw new Error(`Missing JobSaveContext`);
  }
  return context;
}

export function JobSaveProvider({ children }: PropsWithChildren) {
  const { getJob, unsetModifiedJob } = useJobActions();

  const isJobEditable = useIsJobEditable();

  const { mutate } = useMutation({
    mutationFn: webapi.jobs.update,
    onSuccess: () => {
      unsetModifiedJob();
    },
  });

  const save = useCallback(
    (options?: TSaveOptions) => {
      const job = getJob();
      if (!job) return;
      if (!isJobEditable) return;
      mutate({ job }, options);
    },
    [getJob, isJobEditable, mutate]
  );

  return (
    <JobSaveContext.Provider value={save}>{children}</JobSaveContext.Provider>
  );
}
