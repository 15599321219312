import { JobGroup } from "@streamtimefe/entities";
import { useJobGroup, useJobGroupActions } from "@streamtimefe/state";
import { webapi } from "@streamtimefe/state/api";
import { useMutation } from "@tanstack/react-query";
import type { PropsWithChildren } from "react";
import { createContext, useCallback, useContext } from "react";

import type { TSaveOptions } from "./types";

type TJobGroupSaveContext = (options?: TSaveOptions) => void;

export const JobGroupSaveContext = createContext<TJobGroupSaveContext>(
  undefined as unknown as TJobGroupSaveContext
);

export function useJobGroupSave() {
  const context = useContext(JobGroupSaveContext);
  if (!context) {
    throw new Error(`Missing JobGroupSaveContext`);
  }
  return context;
}

export function JobGroupSaveProvider({ children }: PropsWithChildren) {
  const { getJobGroup, unsetModifiedJobGroup } = useJobGroupActions();

  const isStatusClosed = useJobGroup(JobGroup.get.isStatusClosed);

  const { mutate } = useMutation({
    mutationFn: webapi.jobGroups.update,
    onSuccess: () => {
      unsetModifiedJobGroup();
    },
  });

  const save = useCallback(
    (options?: TSaveOptions) => {
      const jobGroup = getJobGroup();
      if (!jobGroup) return;
      if (isStatusClosed) return;
      mutate({ jobGroup }, options);
    },
    [getJobGroup, isStatusClosed, mutate]
  );

  return (
    <JobGroupSaveContext.Provider value={save}>
      {children}
    </JobGroupSaveContext.Provider>
  );
}
