import { defaultSortSearchItems } from "../sort";
import { searchWordMatches } from "../strings";
import type { TSearchItem, TSearchItemId } from "./SearchItem";

export function create<T>(
  id: TSearchItemId,
  value: string,
  search: string,
  item: T
): TSearchItem<T> {
  return {
    id,
    value,
    search,
    item,
  };
}

export function createSortedArray<T>(
  items: T[],
  searchItemCreator: (item: T) => TSearchItem<T>,
  sortCompareFn: typeof defaultSortSearchItems = defaultSortSearchItems
) {
  return items.map(searchItemCreator).sort(sortCompareFn);
}

export function defaultExclude<T, I extends TSearchItem<T> = TSearchItem<T>>(
  searchItems: I[],
  excludeKeys: TSearchItemId[]
) {
  return searchItems.filter(({ id: key }) => !excludeKeys.includes(key));
}

export function defaultSearch<T, I extends TSearchItem<T> = TSearchItem<T>>(
  searchItems: I[],
  query: string
) {
  return searchItems.filter(({ search }) => searchWordMatches(search, query));
}

export function getGroupedSearchItemsCount<T>(
  searchItems: Record<string, TSearchItem<T>[]>
) {
  return Object.values(searchItems).flatMap((v) => v).length;
}
