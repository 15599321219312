import type { ConstEnum } from "@streamtimefe/types";
import { createEmitter } from "st-shared/lib";

export const RECEIVE_WEB_HOTKEY = "web_hotkey";

export const Hotkeys = {
  Enter: "hotkey-enter",
  Esc: "hotkey-esc",
  Left: "hotkey-left",
  Right: "hotkey-right",
  Up: "hotkey-up",
  Down: "hotkey-down",
  ShiftA: "hotkey-shift-a",
  ShiftE: "hotkey-shift-e",
  ShiftI: "hotkey-shift-i",
  ShiftQ: "hotkey-shift-q",
  ShiftT: "hotkey-shift-t",
  ShiftU: "hotkey-shift-u",
  Shortcuts: "hotkey-shortcuts",
  New: "hotkey-new",
  Edit: "hotkey-edit",
  FocusSearch: "hotkey-focus-search",
  CloseSideMenu: "hotkey-close-side-menu",
  OpenSideMenu: "hotkey-open-side-menu",
  GoBack: "hotkey-goback",
  ClearSideFilters: "hotkey-clear-filters",
  I: "hotkey-i",
  L: "hotkey-l",
  P: "hotkey-p",
  Q: "hotkey-q",
  R: "hotkey-r",
  Z: "hotkey-z",
} as const;

export type THotkeys = ConstEnum<typeof Hotkeys>;

export const hotkeyEmitter = createEmitter<THotkeys>();

export function hotkeyFromWeb(hotkey: THotkeys) {
  hotkeyEmitter.emit(hotkey);
}
