import { clsx } from "clsx";
import type { ReactNode } from "react";
import type {
  DialogProps,
  DialogTriggerProps as AriaDialogTriggerProps,
  PopoverProps as AriaPopoverProps,
} from "react-aria-components";
import {
  Dialog,
  DialogTrigger as AriaDialogTrigger,
  OverlayArrow,
  Popover as AriaPopover,
} from "react-aria-components";

import { useTheme } from "../core";
import {
  overlayArrowStyle,
  overlayArrowSVGStyleVariants,
  popoverDialogStyle,
  popoverStyle,
} from "./styles.css";

export type PopoverProps = Omit<AriaPopoverProps, "children"> & {
  variant?: "light" | "dark";
  noPadding?: boolean;
  children: ReactNode;
  slotProps?: {
    dialog?: Omit<DialogProps, "children">;
  };
};

/**
 * A Popover is an overlay element positioned relative to a trigger.
 * The Popover component is built on the [React Aria Popover Component](https://react-spectrum.adobe.com/react-aria/Popover.html).
 * A full list of props available to the Popover component can be found in the
 * [props section of the documentation](https://react-spectrum.adobe.com/react-aria/Popover.html#props).
 *
 * ### Usage
 * A Popover is a more interactive and content-rich component than the Tooltip.
 * It appears when a user clicks on an element. It can contain various elements
 * like links, buttons, or forms, and remains visible until the user clicks outside
 * of it or performs an action within it. Popovers are suitable for more detailed
 * information or actions that require user interaction.
 *
 * You may want to use a Popover when displaying additional settings, action menus,
 * or detailed explanations that require user interaction.
 *
 * ### Accessibility
 * Popover is click-triggered to ensure that the content is intentionally revealed,
 * supporting keyboard and screen reader users by ensuring interactive content is accessible
 * and manageable.
 */

export function Popover({
  children,
  className,
  slotProps,
  variant = "dark",
  noPadding,
  ...rest
}: PopoverProps) {
  // The theme is needed because a popover is a portal and is not a child of the parent component.
  const theme = useTheme();

  return (
    <AriaPopover
      {...rest}
      className={clsx(theme, popoverStyle({ variant, noPadding }), className)}
      offset={rest.offset ?? 0}
    >
      <OverlayArrow className={overlayArrowStyle}>
        <svg
          width={11}
          height={11}
          viewBox="0 0 11 11"
          className={overlayArrowSVGStyleVariants[variant]}
        >
          <path d="M0 0L5.5 6L11 0H0Z" />
        </svg>
      </OverlayArrow>
      <Dialog
        {...slotProps?.dialog}
        className={clsx(popoverDialogStyle, slotProps?.dialog?.className)}
      >
        {children}
      </Dialog>
    </AriaPopover>
  );
}

export function PopoverTrigger({ children, ...rest }: AriaDialogTriggerProps) {
  return <AriaDialogTrigger {...rest}>{children}</AriaDialogTrigger>;
}
