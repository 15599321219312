import type { TEntityDescriptorEnum } from "@streamtimefe/entities";
import type { ConstEnum } from "@streamtimefe/types";
import type { TEntityId } from "st-shared/entities/Entity";

import { WebAppApiException } from "../../exceptions/WebAppApiException";
import WebAppAPI from "..";

// send
const SEND_EDIT_MODE_UPDATE = "editModeUpdate";
const SEND_SHOW_PAGE_LOADING_MASK = "showPageLoadingMask";
const SEND_HIDE_PAGE_LOADING_MASK = "hidePageLoadingMask";
const SEND_OPEN_INTERCOM_CHAT = "openIntercomChat";
const SEND_ADD_HISTORY = "addHistory";
const SEND_STAR_ENTITY = "starEntity";
const SEND_START_PRODUCT_ONBOARDING = "startProductOnboarding";
const SEND_PAGE_VIEW_SCHEDULE = "viewSchedule";
const SEND_ACTIVITY_ENTRY_CLICK = "processActivityEntryClick";
const SEND_NAVIGATE_TO_ENTITY = "navigateToEntity";
const SEND_OPEN_PREMIUM_ONLY_MODAL = "openPremiumOnlyModal";
const SEND_OPEN_CREATE_TEAM_MEMBER_MODAL = "openCreateTeamMemberModal";
const SEND_CONFIRM_COMPLETE_JOB_ITEM = "confirmCompleteJobItem";
const SEND_CONFIRM_COMPLETE_JOB = "confirmCompleteJob";
const SEND_CONFIRM_JOB_ASSIGN_ROLE_TIME = "confirmJobAssignRoleTime";
const SEND_CONFIRM_COMPLETE_JOB_ITEM_USER = "confirmCompleteJobItemUser";
const SEND_CONFIRM_UNASSIGN_JOB_ITEM_USER = "confirmUnassignJobItemUser";
const SEND_CONFIRM_DELETE_JOB_ITEM = "confirmDeleteJobItem";
const SEND_CONFIRM_DELETE_JOB_ITEM_USER = "confirmDeleteJobItemUser";
const SEND_CONFIRM_CLEAR_DATE_JOB_ITEM = "confirmClearDateJobItem";
const SEND_DELETE_JOB_PHASE = "deleteJobPhase";
const SEND_CONFIRM_JOB_PHASE_SET_BILLABLE = "confirmJobPhaseSetBillable";

const SEND_DISPLAY_404_ERROR = "display404Error";
const SEND_DISPLAY_DELETED_ENTITY_ERROR = "displayDeletedEntityError";

export const RECEIVE_FORCE_SAVE = "forceSave";

// types

export const ProductOnboardingMode = {
  Job: 1,
} as const;
export type TProductOnboardingMode = ConstEnum<typeof ProductOnboardingMode>;

export const PremiumOnlyModalMode = {
  AccountingConnect: "ACCOUNTING_CONNECT",
  AccountingCopy: "ACCOUNTING_COPY",
  PurchaseOrder: "PURCHASE_ORDER",
  RateCards: "RATE_CARDS",
  Collaboration: "COLLABORATION",
  Branch: "BRANCH",
} as const;
export type TPremiumOnlyModalMode = ConstEnum<typeof PremiumOnlyModalMode>;

// calls

export function feToWebSendIsInEditingMode(editing: boolean) {
  WebAppAPI.sendAction(SEND_EDIT_MODE_UPDATE, editing);
}

export function feToWebShowPageLoadingMask(zIndex?: number) {
  WebAppAPI.sendAction(SEND_SHOW_PAGE_LOADING_MASK, zIndex);
}

export function feToWebHidePageLoadingMask() {
  WebAppAPI.sendAction(SEND_HIDE_PAGE_LOADING_MASK);
}

export function feToWebDisplay404Error() {
  WebAppAPI.sendAction(SEND_DISPLAY_404_ERROR);
}

export function feToWebDisplayDeletedEntityError() {
  WebAppAPI.sendAction(SEND_DISPLAY_DELETED_ENTITY_ERROR);
}

export function feToWebOpenIntercomChat() {
  WebAppAPI.sendAction(SEND_OPEN_INTERCOM_CHAT);
}

export function feToWebAddHistory(
  entityDescriptorId: TEntityDescriptorEnum,
  entityName: string,
  entityRoute: string
) {
  WebAppAPI.sendAction(
    SEND_ADD_HISTORY,
    entityDescriptorId,
    entityName,
    entityRoute
  );
}

export function feToWebStarEntity(
  entityDescriptorId: TEntityDescriptorEnum,
  entityName: string,
  entityRoute: string
) {
  WebAppAPI.sendAction(
    SEND_STAR_ENTITY,
    entityDescriptorId,
    entityName,
    entityRoute
  );
}

export function feToWebStartProductOnboarding(mode: TProductOnboardingMode) {
  WebAppAPI.sendAction(SEND_START_PRODUCT_ONBOARDING, mode);
}

export function feToWebViewSchedule(jobIds: TEntityId[]) {
  WebAppAPI.sendAction(SEND_PAGE_VIEW_SCHEDULE, jobIds);
}

export function feToWebActivityEntryClick(activityEntry: unknown) {
  WebAppAPI.sendAction(SEND_ACTIVITY_ENTRY_CLICK, activityEntry);
}

export function feToWebNavigateToEntity(
  entityDescriptorId: TEntityDescriptorEnum,
  entityId: TEntityId
) {
  WebAppAPI.sendAction(SEND_NAVIGATE_TO_ENTITY, entityDescriptorId, entityId);
}

export function feToWebOpenPremiumOnlyModal(mode: TPremiumOnlyModalMode) {
  WebAppAPI.sendAction(SEND_OPEN_PREMIUM_ONLY_MODAL, mode);
}

export function feToWebOpenCreateTeamMemberModal() {
  return new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_OPEN_CREATE_TEAM_MEMBER_MODAL
    );

    if (jQueryDeferred) jQueryDeferred.then(resolve).fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_OPEN_CREATE_TEAM_MEMBER_MODAL}`
        )
      );
  });
}

export function feToWebConfirmCompleteJobItem(jobItem: unknown) {
  return new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_CONFIRM_COMPLETE_JOB_ITEM,
      jobItem
    );

    if (jQueryDeferred) jQueryDeferred.done(resolve).fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_CONFIRM_COMPLETE_JOB_ITEM}`
        )
      );
  });
}

export function feToWebConfirmCompleteJob(totalIncompleteMinutes: number) {
  return new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_CONFIRM_COMPLETE_JOB,
      totalIncompleteMinutes
    );

    if (jQueryDeferred) jQueryDeferred.done(resolve).fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_CONFIRM_COMPLETE_JOB}`
        )
      );
  });
}

export function feToWebConfirmJobAssignRoleTime(data: {
  userId: TEntityId;
  roleId: TEntityId;
  jobId?: TEntityId;
  jobPhaseId?: TEntityId;
}) {
  return new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_CONFIRM_JOB_ASSIGN_ROLE_TIME,
      data
    );

    if (jQueryDeferred) jQueryDeferred.done(resolve).fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_CONFIRM_JOB_ASSIGN_ROLE_TIME}`
        )
      );
  });
}

export function feToWebConfirmCompleteJobItemUser(jobItemUser: unknown) {
  return new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_CONFIRM_COMPLETE_JOB_ITEM_USER,
      jobItemUser
    );

    if (jQueryDeferred) jQueryDeferred.done(resolve).fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_CONFIRM_COMPLETE_JOB_ITEM_USER}`
        )
      );
  });
}

export function feToWebConfirmUnassignJobItemUser(jobItemUser: unknown) {
  return new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_CONFIRM_UNASSIGN_JOB_ITEM_USER,
      jobItemUser
    );

    if (jQueryDeferred) jQueryDeferred.done(resolve).fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_CONFIRM_UNASSIGN_JOB_ITEM_USER}`
        )
      );
  });
}

export function feToWebConfirmDeleteJobItem(
  hasLoggedTime: boolean,
  hasDependencies: boolean
) {
  return new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_CONFIRM_DELETE_JOB_ITEM,
      hasLoggedTime,
      hasDependencies
    );

    if (jQueryDeferred) jQueryDeferred.done(resolve).fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_CONFIRM_DELETE_JOB_ITEM}`
        )
      );
  });
}

export function feToWebConfirmDeleteJobItemUser() {
  return new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_CONFIRM_DELETE_JOB_ITEM_USER
    );

    if (jQueryDeferred) jQueryDeferred.done(resolve).fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_CONFIRM_DELETE_JOB_ITEM_USER}`
        )
      );
  });
}

export function feToWebConfirmClearDateJobItem() {
  return new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_CONFIRM_CLEAR_DATE_JOB_ITEM
    );

    if (jQueryDeferred) jQueryDeferred.done(resolve).fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_CONFIRM_CLEAR_DATE_JOB_ITEM}`
        )
      );
  });
}

export function feToWebSendDeleteJobPhase(
  jobPhaseId: TEntityId,
  replacementPhaseId: TEntityId,
  replacementPhaseName: string,
  jobItemCount: number,
  hasLoggedTime: boolean,
  hasDependencies: boolean
) {
  WebAppAPI.sendAction(SEND_DELETE_JOB_PHASE, {
    jobPhaseId,
    replacementPhaseId,
    replacementPhaseName,
    jobItemCount,
    hasLoggedTime,
    hasDependencies,
  });
}

export function feToWebConfirmJobPhaseSetBillable(isBillable: boolean) {
  return new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_CONFIRM_JOB_PHASE_SET_BILLABLE,
      isBillable
    );

    if (jQueryDeferred) jQueryDeferred.done(resolve).fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_CONFIRM_JOB_PHASE_SET_BILLABLE}`
        )
      );
  });
}
