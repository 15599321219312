import type { Dictionary } from "@streamtimefe/types";
import { filter, isDefined, map, pipe, unique } from "remeda";

import type { TEntityId } from "../../core";
import type { TJobItemUser } from "../JobItemUser";
import { type TUser } from "../User";
import { get } from "./User.utils";

export function costRate(userId: TEntityId, users: TUser[]) {
  const user = users.find((v) => v.id === userId);
  if (user) {
    return get.costRate(user);
  } else {
    return 0;
  }
}

export type TUserNameIndex = Dictionary<TEntityId, string>;
export function createUserNameIndex(users: TUser[]) {
  const index: TUserNameIndex = {};
  for (const user of users) {
    index[user.id] = user.displayName ?? "";
  }
  return index;
}

export function fromJobItemUsers(
  jobItemUsers: TJobItemUser[],
  users: Dictionary<TEntityId, TUser>
) {
  return pipe(
    jobItemUsers,
    map(({ userId }) => userId),
    unique(),
    map((userId) => users[userId]),
    filter(isDefined)
  );
}
