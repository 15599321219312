import type { TSavedSegmentTypeEnum } from "@streamtimefe/entities";
import { SavedSegmentTypeEnum } from "@streamtimefe/entities";
import type { ConstEnum } from "@streamtimefe/types";

export const UserPreferenceKeyEnum = {
  ReportingSideMenuOpen: "REPORTING_SIDE_MENU_OPEN",
  ReportingSideSelectedTab: "REPORTING_SIDE_MENU_SELECTED_TAB",
  DashboardSearchState: "DASHBOARD_SEARCH_STATE",
  ScheduleLeftSidebarWidth: "SCHEDULE_LEFT_SIDEBAR_WIDTH",
  JobGroupDefaults: "JOB_GROUP_DEFAULTS",
  JobChartGraphMode: "JOB_CHART_GRAPH_MODE",
  JobGroupBottomSectionTabState: "JOB_GROUP_BOTTOM_SECTION_TAB_STATE",
  JobGroupMetricTotalsTabState: "JOB_GROUP_METRIC_TOTALS_TAB_STATE",
  JobGroupChartDisplayMode: "JOB_GROUP_CHART_DISPLAY_MODE",
  JobBottomSectionTabState: "JOB_BOTTOM_SECTION_TAB_STATE",
  JobChartDisplayMode: "JOB_CHART_DISPLAY_MODE",
  JobRelativeInsightDisplayMode: "JOB_RELATIVE_INSIGHT_DISPLAY_MODE",
  JobTotalsDisplayMode: "JOB_TOTALS_DISPLAY_MODE",
  JobSideDrawerTabState: "JOB_SIDE_DRAWER_TAB_STATE",
  JobDatesFilterState: "JOB_DATES_FILTER_STATE",
  JobActivityFilterState: "JOB_ACTIVITY_FILTER_STATE",
  JobItemDefaultCostingMethod: "JOB_ITEM_DEFAULT_COSTING_METHOD",
  GoogleCalendarInboxDismissedTurnkey:
    "GOOGLE_CALENDAR_INBOX_DISMISSED_TURNKEY",
  TeamMemberSelectState: "TEAM_MEMBER_SELECT_STATE",
  ...createSavedSegmentSidebarUserPreferences(),
  ...createEncodedSearchUserPreferences(),
} as const;

export type TUserPreferenceKeyEnum = ConstEnum<typeof UserPreferenceKeyEnum>;

function createSavedSegmentSidebarUserPreferences() {
  type SavedSegmentSidebarKeys = Record<
    `SavedSegmentSidebar_${TSavedSegmentTypeEnum}`,
    `SAVED_SEGMENT_SIDEBAR_${TSavedSegmentTypeEnum}`
  >;
  const keys: SavedSegmentSidebarKeys = {} as SavedSegmentSidebarKeys;
  Object.values(SavedSegmentTypeEnum).map((type) => {
    keys[`SavedSegmentSidebar_${type}`] = `SAVED_SEGMENT_SIDEBAR_${type}`;
  });
  return keys;
}

function createEncodedSearchUserPreferences() {
  type SavedSegmentSidebarKeys = Record<
    `EncodedSearch_${TSavedSegmentTypeEnum}`,
    `ENCODED_SEARCH_${TSavedSegmentTypeEnum}`
  >;
  const keys: SavedSegmentSidebarKeys = {} as SavedSegmentSidebarKeys;
  Object.values(SavedSegmentTypeEnum).map((type) => {
    keys[`EncodedSearch_${type}`] = `ENCODED_SEARCH_${type}`;
  });
  return keys;
}
